/* src/App.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:focus {
  outline: 0;
}
::-webkit-scrollbar {
  display: none;
}
a,
a:after,
a:hover,
a:visited {
  text-decoration: none;
  color: #bdc1c6;
}
html {
  max-width: 500px;
  margin: 0 auto;
  background: #202124;
}
body {
  color: #bdc1c6;
  font-family: 'Noto Sans', arial, sans-serif;
}
.atas {
  display: grid;
  min-height: 100vh;
}
.atasbox {
  margin: auto;
  text-align: center;
}
.ataslink {
  display: inline-grid;
  margin: 0.88rem 0;
}
.ataslink a {
  padding: 0.5rem 3.8rem;
  background: #33333388;
  margin-bottom: 0.5rem;
  border-radius: 0.38rem;
  box-shadow: 0 -1px #ccb38a88;
  letter-spacing: 1px;
}
.ataslink a.btn1 {
  color: #eee;
  background-image: linear-gradient(to right, #cb356b, #bd3f32);
  box-shadow: none;
  font-weight: bold;
}
.imghero {
  box-shadow: inset 0 0 0 8px #888;
  border-radius: 8px;
}
